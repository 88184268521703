export default {
    "/": {
        title: "Rarchy - Website Planning Tool, Visual Sitemaps & More",
        description: "Plan your next website using our fast and easy website planning tool. Collaborate in real-time with your whole team and improve your UX. Try us for free today!"
    },
    "/pricing": {
        title: "Our Pricing Plans | Rarchy",
        description: "Whether you're at a Fortune 500 company or a startup, Rarchy can help you plan your website and improve your user experience. Try us for free today!"
    },
    "/sitemaps": {
        title: "Visual Sitemap Builder & Website Mapping Tool | Rarchy",
        description: "Use our visual sitemap mapping tool to visually present and understand your websites information architecture in a clearer and more understandable way."
    },
    "/sitemaps/visual-sitemap-generator": {
        title: "Free Visual Sitemap Generator | Rarchy",
        description: "Use our free visual sitemap generator and website crawler. Generate an up-to-date visualization of your website's structure with Rarchy."
    },
    "/solutions/agencies": {
        title: "Rarchy for Agencies | Rarchy",
        description: "Learn how agencies are using Rarchy to make website planning more visual, and more collaborative."
    },
    "/user-flow": {
        title: "Free User Flow Chart & Diagram Creator | Rarchy",
        description: "Map out users potential pathways through your website using our user flow tool, part of our website planning suite. Try us for free today !"
    },
    "/support": {
        title: "Support | Get in touch with us | Rarchy",
        description: "Need some help? Get in touch and we'll get back to you as soon as possible - generally within one business day."
    },
}